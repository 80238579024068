import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CabecalhoComponent } from './cabecalho/cabecalho.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ValidatorClassComponent } from './forms/validator-class/validator-class.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NotFoundComponent } from './not-found/not-found.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { DatatableComponent } from './datatable/datatable.component';
import { AlertComponent } from './alert/alert.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import { ScannerComponent } from './scanner/scanner.component';
import { NgxScannerQrcodeModule, LOAD_WASM } from 'ngx-scanner-qrcode';
import { NfcComponent } from './nfc/nfc.component';

LOAD_WASM().subscribe();



@NgModule({
  declarations: [
    CabecalhoComponent,
    DatatableComponent,
    ValidatorClassComponent,
    NotFoundComponent,
    SpinnerComponent,
    AlertComponent,
    ScannerComponent,
    NfcComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatPaginatorModule,
    NgxScannerQrcodeModule
  ],
  exports: [
    CabecalhoComponent,
    DatatableComponent,
    ValidatorClassComponent,
    SpinnerComponent,
    AlertComponent,
    ScannerComponent,
    NfcComponent
  ],
  providers:[

  ]
})
export class GenericModule { }
