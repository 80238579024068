<app-cabecalho titulo="Redefinição de Senha" rotaanterior="/login">
</app-cabecalho>

<div class="container-fluid container-form container-box mt-2 p-2">
    <form [formGroup]="formresetsenha" (submit)="onSubmit()" *ngIf="sucesso===0">
        <div class="row   d-flex justify-content-center">
            <div class="form-group col-md-4">
                <label for="senha">Senha</label>
                <app-validator-class
                    [status]="formresetsenha.controls.senha.valid || !formresetsenha.controls.senha.touched">
                    <input type="senha" class="form-control" id="senha" placeholder="senha" formControlName="senha"
                        type="password">
                </app-validator-class>
            </div>
        </div>
        <div class="row  d-flex justify-content-center">
            <div class="form-group col-md-4">
                <label for="confirmacaosenha">Confirmação de Senha</label>
                <app-validator-class
                    [status]="formresetsenha.controls.confirmacaosenha.valid || !formresetsenha.controls.confirmacaosenha.touched">
                    <input type="confirmacaosenha" class="form-control" id="confirmacaosenha"
                        placeholder="Confirmação de Senha" type="password" formControlName="confirmacaosenha">
                </app-validator-class>
            </div>
        </div>
        <div class="row d-flex justify-content-center">
            <div class="col-md-4   d-flex justify-content-end align-items-end">
                <button type="submit" class="btn_icon"><i class="fa-solid fa-floppy-disk"></i> Enviar</button>
            </div>
        </div>
    </form>
    <div class="alert alert-success" role="alert" *ngIf="sucesso===1">
        <p>Senha Atualizada com sucesso.</p>        
    </div>
    <div class="alert alert-danger" role="alert" *ngIf="sucesso===2">
        <p>Ocorreu um erro ao executar esta operação.</p>
        <p>Por favor tente novamente mais tarde.</p>
    </div>
</div>