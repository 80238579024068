import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-nfc',
  templateUrl: './nfc.component.html',
  styleUrls: ['./nfc.component.css']
})
export class NfcComponent {

  @Input() modo: number = 2; // modo 2 leitura initerrupta e 2 com stop na primeira leitura
  @Output() tag = new EventEmitter<string>;
  ndef;
  constructor() { 

      
      if ('NDEFReader' in window) {
        this.ndef = new (window as any).this.NDEFReader();
        
        this.read() 
      }else{
        alert('sem suporte para nfc');
        
      }
    }

  read() {
    this.ndef.scan().then((tag) => {
      alert("Scan started successfully.");
      
      console.log(tag);
      
      this.ndef.onreading = (event)=>{
        alert(event);
        this.tag.emit(event)
        return event
      }

      this.ndef.onreadingerror = (event) => {
        alert("Error! Cannot read data from the NFC tag. Try a different one?");
      };
      
    }).catch(error => {
      alert(`Error! Scan failed to start: ${error}.`);
    });
  
}
  
  
  
  asNFC(){
    if ("NDEFReader" in window){
      return true
    }else{
      return false
    }
  }
}
