import { Component, OnInit, Renderer2 } from '@angular/core';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { GenericService } from 'src/app/shared/services/generic.service';
import { TagflowService } from '../../generic/services/tagflow/tagflow.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  isautenticado: boolean = true  
  site: string = environment.urlsite
  tagflow: any = ''
  constructor(private authgard: AuthGuard,
    private router: Router,
    private renderer: Renderer2,
    private authguard: AuthGuard,
    private genericservice: GenericService,
    public tagflowservice: TagflowService
  ) { 

  }


  ngOnInit(): void {

    this.tagflow = localStorage.getItem("tagflow")
    this.tagflow = JSON.parse(this.tagflow)
  }

  public urlsite = environment.urlsite

  sair() {    
    localStorage.clear()
    this.authgard.setAutenticated(false)
  }

/**
 * 1 Basico
 * 2 Intermediario
 * 3 Administrador
 */
  ngDoCheck(): void {
    this.isautenticado = this.genericservice.verificaSeRotaLogado(this.router.url.split('/')[1])   
  }
}
