import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule, Router, CanActivate, CanActivateChild } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { NotFoundComponent } from './modules/generic/not-found/not-found.component';

import { EsqueciSenhaComponent } from './modules/inicio/esqueci-senha/esqueci-senha.component';
import { LoginComponent } from './modules/inicio/login/login.component';
import { PrimeiroAcessoComponent } from './modules/inicio/primeiro-acesso/primeiro-acesso.component';
import { RedefinirSenhaComponent } from './modules/inicio/redefinir-senha/redefinir-senha.component';
import { HomeComponent } from './modules/inicio/home/home.component';
import { MenuComponent } from './modules/inicio/menu/menu.component';




const routes: Routes = [

  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'primeiroacesso',
    component: PrimeiroAcessoComponent
  },
  {
    path: 'redefinir-senha/:token',
    component: RedefinirSenhaComponent
  },
  {
    path: 'esqueci-a-senha',
    component: EsqueciSenhaComponent
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: HomeComponent,
  },
  {
    path: 'crud',
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./modules/cadastro/cadastro.module').then(c => c.CadastroModule)
  },

  {
    path: 'gestao-evento',
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./modules/evento/evento.module').then(e => e.EventoModule)
  },
  {
    path: 'inscricao',
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./modules/inscricao/inscricao.module').then(i => i.InscricaoModule)
  },
  {
    path: 'usuario',
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./modules/funcionario/funcionario.module').then(f => f.FuncionarioModule)
  },

  {
    path: '**',
    canActivateChild: [AuthGuard],
    component: NotFoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
