import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatusfuncionarioService {


  constructor(private http: HttpClient) { }

  getStatusFuncionarios(): Observable<any> {
    return this.http.get<Observable<any>>(`${environment.baseApiUrl}/statusfuncionario`);
  }

  getStatusFuncionarioById(idstatus_funcionario: number): Observable<any> {
    return this.http.get<Observable<any>>(`${environment.baseApiUrl}/statusfuncionario/${idstatus_funcionario}`);
  }

  getStatusFuncionarioEditById(idstatus_funcionario: number): Observable<any> {
    return this.http.get<Observable<any>>(`${environment.baseApiUrl}/statusfuncionario/edit/${idstatus_funcionario}`);
  }

  updateStatusFuncionario(params: any): Observable<any> {
    return this.http.put<Observable<any>>(`${environment.baseApiUrl}/statusfuncionario`, params);
  }

  newStatusFuncionario(params: any) {
    return this.http.post<Observable<any>>(`${environment.baseApiUrl}/statusfuncionario`, params);
  }

  deleteStatusFuncionario(idstatus_funcionario: number): Observable<any> {
    return this.http.delete<Observable<any>>(`${environment.baseApiUrl}/statusfuncionario/${idstatus_funcionario}`);
  }

  reativarStatusFuncionario(idstatus_funcionario: number): Observable<any> {
    return this.http.put<Observable<any>>(`${environment.baseApiUrl}/statusfuncionario/${idstatus_funcionario}`, null);
  }

}
