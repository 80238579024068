
<hr>
<div class="d-flex justify-content-center m-2">
  <img src="../../../../assets/img/tagflowti_logo_w150.png" id="logo">
</div>
<div class="container p-4">
  <div class="login">
    <form [formGroup]="formlogin" (submit)="onSubmit()">
      <div class="row pb-4">
        <div class="form-group col-md">
          <label for="email">EMAIL</label>
          <app-validator-class [status]="formlogin.controls.email.valid || !formlogin.controls.email.touched">
            <input type="email" class="form-control" id="email" placeholder="exemplo@exemplo.com" formControlName="email">
          </app-validator-class>
        </div>
      </div>
      <div class="row pb-4">
        <div class="form-group col-md">
          <label for="senha">Senha</label>
          <app-validator-class [status]="formlogin.controls.senha.valid || !formlogin.controls.senha.touched">
            <input type="password" class="form-control d-block" id="senha" placeholder="insira sua senha" formControlName="senha">
          </app-validator-class>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="form-group col-md  col-6 col-sm-6 d-flex justify-content-center">
          <button class="btn_enviar" type="submit" [disabled]="!formlogin.valid">Enviar</button>
        </div>
      <div class="form-group col-md col-6 col-sm-6 ">
        <div class="row">

          <div class="form-group pb-1">
            <a href="/esqueci-a-senha">Esqueci a senha.</a>
          </div>
        </div>
        <div class="row">

          <div class="form-group pt-1">
            <a href="/primeiroacesso">Primeiro Acesso.</a>
          </div>
        </div>
      </div>
      
    
      </div>
    </form>
  </div>
</div>

<hr>
<div id="rodape" class="mb-0">
  <p class="bg-primary ">TagFlowTi</p>
</div>