<app-cabecalho titulo=" Inicio">
    <a type="button" class="btn_icon" routerLink="/inscricao/inscricaofuncao">
        Incrição
    </a>
</app-cabecalho>
<div class="container-fluid container-form container-box mt-2 p-2">
    <div class="row p-3">
        <div class="col table-responsive m-0 p-0">
            <table class="table tabela">
                <thead>
                    <tr>
                        <th>Evento</th>
                        <th>Inicio</th>
                        <th>Término</th>
                        <th>Função</th>
                        <th>Empresa</th>
                        <th>Data Inscrição</th>
                        <th>Status Inscrição</th>                        
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of inscricoeseventos">

                        <td class="texto">
                            {{item.descricao_evento}}
                        </td>
                        <td class="texto">{{item.inicio}}</td>
                        <td class="texto">{{item.termino}}</td>
                        <td class="texto">
                            {{item.descricao_funcao}}</td>
                            <td class="texto">
                                {{item.empresa}}</td>
                        <td class="texto">
                            {{item.data_hora_inscricao}}</td>
                        <td >

                            <div  [ngClass]="{
                                    'inscricao-status-analise': (item.idstatus_inscricao == 1),
                                    'inscricao-status-aprovada': (item.idstatus_inscricao == 2),
                                    'inscricao-status-nao-aprovada': (item.idstatus_inscricao == 3),
                                    'inscricao-status-cancelada': (item.idstatus_inscricao == 4)
                                    }">
                                {{item.status_inscricao}}
                            </div>
                           </td>

                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>