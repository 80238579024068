import { Component, OnInit, Input } from '@angular/core';
import { SpinnerService } from '../services/spinner/spinner.service';
import { AuthGuard } from '../../../core/guards/auth.guard';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cabecalho',
  templateUrl: './cabecalho.component.html',
  styleUrls: ['./cabecalho.component.css']
})
export class CabecalhoComponent implements OnInit {

  @Input() titulo: string = "";
  @Input() rotaanterior: string = "";
  constructor(
    private authgard: AuthGuard,
    private router: Router
  ) { }

  ngOnInit(): void {

  }

}
