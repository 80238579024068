import { Injectable } from '@angular/core';
import { TagFlow } from '../../interface/alert/tagflow';
import { Subject,Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TagflowService {
  public tagflow: Subject<TagFlow> = new Subject<TagFlow>();

  constructor() {}

  setTagFlow(tagflow: TagFlow) {
    this.tagflow.next(tagflow);
  }

  setTagFlowVazio() {
    this.tagflow.next({
      Authorization: '',
      funcionario: {
        celular: '',
        cpf: '',
        email: '',
        foto: '',
        idfuncionario: 0,
        idperfil: 0,
        nome: '',
        perfil: '',
        status_funcionario: '',
      },
      status: 0,
    });
  }

  getTagFlow(): Observable<TagFlow> {
    return this.tagflow.asObservable();
  }
}
