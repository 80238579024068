<app-cabecalho titulo="Inscrever-se na Função" rotaanterior="/inscricao">
</app-cabecalho>

<div class="container-fluid container-form container-box mt-2 p-2">
    <form [formGroup]="formfiltraevento">
        <div class="row">
            <div class="form-group col-md-4">
                <label for="idevento">Evento</label>
                <app-validator-class
                    [status]="formfiltraevento.controls.idevento.valid || !formfiltraevento.controls.idevento.touched">
                    <select class="form-select form-control " id="idevento" placeholder="Selecione o Evento"
                        (change)=" onSubmitFormFiltraEvento()" formControlName="idevento">
                        <option value=""></option>
                        <option *ngFor="let item of eventos" value="{{item.idevento}}">{{item.nome}}</option>
                    </select>
                </app-validator-class>
            </div>
        </div>
    </form>
</div>
<div class="container-fluid container-form container-box mt-2 p-2" *ngIf="evento">
    <div class="row">
        <div class="form-group col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
            <label for="nome">Nome</label>
            <input type="text" disabled class="form-control" value={{evento.nome}}>
        </div>
        <div class="form-group col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
            <label for="inicio">Início</label>
            <input type="text" disabled class="form-control" value={{evento.inicio}}>
        </div>
        <div class="form-group col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
            <label for="termino">Término</label>
            <input type="text" disabled class="form-control" value={{evento.termino}}>
        </div>
        <div class="form-group col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
            <label for="termino">Status</label>
            <input type="text" disabled class="form-control" value={{evento.status_evento}}>
        </div>
    </div>

    <div class="row p-3" *ngIf="!inscricao || inscricao?.idstatus_inscricao === '4'">
        <div class="col table-responsive m-0 p-0">
            <table class="table tabela">
                <thead>
                    <tr>
                        <th>FUNÇÃO</th>
                        <th>EMPRESA<th>
                        <th>AÇÕES</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of eventofuncoes">
                        <td class="texto">{{item.funcao}}</td>
                        <td class="texto">{{item.empresa}}</td>     
                        <td></td>                   
                        <td class="texto">                            
                            <form [formGroup]="formfiltraevento">
                                <a class="m-2" title="Inscrever" (click)="onSubmitFormInscricaoEvento(item)">
                                    <i class="far fa-check-circle botaoacaoinscricao"></i>
                                </a>
                            </form>
                        </td>
                       
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row p-3" *ngIf="inscricao === null && eventofuncoes.length === 0">
        <div class="col table-responsive m-0 p-0">
            <p>Não há funções cadastradas para este evento.</p>
        </div>
    </div>
</div>
<div class="container-fluid container-form container-box mt-2 p-2" *ngIf="inscricao && inscricao?.idstatus_inscricao != '4'">
    <div class="row p-3">
        <div class="form-group col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
            <label for="funcao">Funcao</label>
            <input type="text" disabled class="form-control" value={{inscricao.funcao}}>
        </div>
        <div class="form-group col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
            <label for="funcao">Empresa</label>
            <input type="text" disabled class="form-control" value={{inscricao.empresa}}>
        </div>
        <div class="form-group col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
            <label for="data_cadastro">Data de Inscrição</label>
            <input type="text" disabled class="form-control" value={{inscricao.data_cadastro}}>
        </div>
        <div class="form-group col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
            <label for="termino">Status</label>           
            <div  [ngClass]="{
                'inscricao-status-analise': (inscricao.idstatus_inscricao == 1),
                'inscricao-status-aprovada': (inscricao.idstatus_inscricao == 2),
                'inscricao-status-nao-aprovada': (inscricao.idstatus_inscricao == 3),
                'inscricao-status-cancelada': (inscricao.idstatus_inscricao == 4)
                }">
            {{inscricao.status_inscricao}}
        </div>
        </div>
    </div>
    <div class="row p-3">
        <div class="form-group col d-flex justify-content-end align-items-end">
            <form [formGroup]="formcancelarinscricao">
                <a type="button" class="btn_icon" (click)="onSubmitFormCancelarInscricao()">
                    <i class="far fa-window-close"></i>
                    Cancelar Incrição
                </a>
            </form>
        </div>
    </div>
</div>