import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SpinnerService } from '../../../modules/generic/services/spinner/spinner.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertService } from 'src/app/modules/generic/services/alert/alert.service';
import { Router } from '@angular/router';

@Injectable()
export class HttpResInterceptor implements HttpInterceptor {

  constructor(
    public spinnerservice: SpinnerService,
    public alertservice: AlertService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map((res: any) => {
        if (res.body)
          this.spinnerservice.hide();
        else
          this.spinnerservice.show()
        return res
      }),
      catchError((err: HttpErrorResponse) => {        
        
        this.alertservice.setValue({
          status: err.status,
          mensagem: err.error.dado?.mensagem || err.error.dado ,
        })
        this.spinnerservice.hide()
        if (err.status === 401)
          this.router.navigate(
            ["/login"]
          );
        return throwError(() => '');
      })
    )

  }

}
