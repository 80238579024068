import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericService } from 'src/app/shared/services/generic.service';
import { RedefinirSenhaService } from '../services/redefinir-senha/redefinir-senha.service';

@Component({
  selector: 'app-redefinir-senha',
  templateUrl: './redefinir-senha.component.html',
  styleUrls: ['./redefinir-senha.component.css']
})
export class RedefinirSenhaComponent {

  formresetsenha: any = FormGroup
  token: string = ''

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formbuilder: FormBuilder,
    private genericservice: GenericService,
    private redefinirsenhaservice: RedefinirSenhaService

  ) {
  }

  ngOnInit(): void {

    this.getParams()
    this.loadForm()
  }

  getParams() {
    this.route.params.subscribe(params => {
      if (params['token']) {
        this.token = params['token']
      }
    })
  }

  loadForm() {
    this.formresetsenha = this.formbuilder.group({
      senha: ['', [Validators.required, Validators.minLength(8), this.genericservice.validaComplexidade]],
      confirmacaosenha: ['', Validators.required]
    }, {
      validator: this.genericservice.validaSenha
    })
  }


  sucesso: any = 0;
  onSubmit() {
    let params: any = this.formresetsenha.value;
    params['token'] = this.token
    this.redefinirsenhaservice.redefinirSenha(params).subscribe({
      next: (result) => {
        this.sucesso = 1
        setTimeout(() => this.redirecionaConsulta(), 5000)
      },
      error: (e) => {
        this.sucesso = 2
        setTimeout(() => this.redirecionaConsulta(), 5000)
      }
    })

  }

  redirecionaConsulta() {
    this.router.navigate(
      ["/login"]
    );
  }



}
