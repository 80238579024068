import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InscricaoRoutingModule } from './inscricao-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ConsultaInscricoesComponent } from './consulta-inscricoes/consulta-inscricoes.component';
import { InscricaoFuncaoComponent } from './inscricao-funcao/inscricao-funcao.component';
import { GenericModule } from '../generic/generic.module';


@NgModule({
  declarations: [
    ConsultaInscricoesComponent,
    InscricaoFuncaoComponent
  ],
  imports: [
    CommonModule,
    InscricaoRoutingModule,
    ReactiveFormsModule,
    GenericModule
  ],
  exports:[
    ConsultaInscricoesComponent
  ]
})
export class InscricaoModule { }
