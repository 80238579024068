<div class="d-flex justify-content-center" >

	<div class="col-md-4 col-6 col-sm-5">
		
		
		<ngx-scanner-qrcode #action="scanner"   (event)="onEvent($event, action)" (click)="action.start()"></ngx-scanner-qrcode>
		
		<div class="d-flex justify-content-center">
			
			
			
		</div>
	</div>
</div>
<div class="row">
	
		<div class="col-xs-12 col-md-6 col-lg-4 col-xl-3 my-1">
			<select #select1 (change)="action.playDevice(select1.value)" class="form-select form-select-md">
			  <option [value]="null" selected>Select device</option>
			  <option *ngFor="let c of action.devices.value; let i = index" [value]="c.deviceId" [selected]="i == action.deviceIndexActive">{{c.label}}</option>
			</select>
		  </div>

</div>

