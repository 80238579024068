import { Component } from '@angular/core';
import { SpinnerService } from './modules/generic/services/spinner/spinner.service';
import { AuthGuard } from './core/guards/auth.guard';
import { Router } from '@angular/router';
import { AlertService } from './modules/generic/services/alert/alert.service';
import { Alert } from './modules/generic/interface/alert/alert';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  mostramenu: boolean = true;

  constructor(
    public spinnerservice: SpinnerService,
    public alertservice: AlertService,
    public authguard: AuthGuard,
    public router: Router
  ) {
    this.spinnerservice.getIsloading().subscribe(isloading => {
      setTimeout(() => {
        this.isloading = isloading
      })
    });

    this.alertservice.getIsAlert().subscribe(alert => {
      this.isalert = true
      this.alert = alert
      setTimeout(() => {
        this.isalert = false
      }, 5000);
    })

  }

  title = 'credenciamento';

  isloading: boolean = false
  isalert: boolean = false
  alert: Alert = {
    status: 0,
    mensagem: ''
  }

  ngOnInit(): void {

  }

  ngDoCheck(): void {

    switch (this.router.url) {
      case '/login':
        this.mostramenu = false;
        break;
      default:
        this.mostramenu = true;
        break;
    }

  }


}
