import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Alert } from '../../interface/alert/alert';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor() { }

  public isalert: Subject<Alert> = new Subject<Alert>();

  setValue(alert: Alert) {
    this.isalert.next(alert);
  }

  getIsAlert(): Observable<Alert> {
    return this.isalert.asObservable();
  }

}
