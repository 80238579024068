import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EsqueciSenhaService {


  constructor(private http: HttpClient) { }

  esqueciASenha(params: any): Observable<any> {
    return this.http.put<Observable<any>>(`${environment.baseApiUrl}/funcionario/esqueci-a-senha`, params);
  }
}
