<div class="div-sobreposta p-0 m-0" [ngSwitch]="alert.status">
    <div *ngSwitchCase="500" class="alert  alert-danger " role="alert">
        <span *ngIf="alert.mensagem">{{alert.mensagem}}</span>
        <span *ngIf="!alert.mensagem">Ocorreu um erro ao executar esta operação.</span>
    </div>
    <div *ngSwitchCase="200" class="alert alert-primary " role="alert">
        <span>{{alert.mensagem}}</span>
    </div>
    <div *ngSwitchCase="403" class="alert  alert-warning " role="alert">
        <span>{{alert.mensagem}}</span>
    </div>
    <div *ngSwitchCase="0" class="alert  alert-warning " role="alert">
        <span>{{alert.mensagem}}</span>
    </div>
    <div *ngSwitchDefault class="alert  alert-warning " role="alert">
        <span *ngIf="alert.mensagem">{{alert.mensagem}}</span>
        <span *ngIf="!alert.mensagem">Ocorreu um erro inesperado. Por favor tente mais tarde.</span>
    </div>
</div>