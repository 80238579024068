import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {

  constructor(private http: HttpClient) { }

  getEmpresas(): Observable<any> {
    return this.http.get<Observable<any>>(`${environment.baseApiUrl}/empresa`);
  }

  getEmpresaById(idempresa: number): Observable<any> {
    return this.http.get<Observable<any>>(`${environment.baseApiUrl}/empresa/${idempresa}`);
  }

  getEmpresaEditById(idempresa: number): Observable<any> {
    return this.http.get<Observable<any>>(`${environment.baseApiUrl}/empresa/edit/${idempresa}`);
  }

  updateEmpresa(params: any): Observable<any> {
    return this.http.put<Observable<any>>(`${environment.baseApiUrl}/empresa`, params);
  }

  newEmpresa(params: any) {
    return this.http.post<Observable<any>>(`${environment.baseApiUrl}/empresa`, params);
  }

  deleteEmpresa(idempresa: number): Observable<any> {
    return this.http.delete<Observable<any>>(`${environment.baseApiUrl}/empresa/${idempresa}`);
  }

  reativarEmpresa(idempresa: number): Observable<any> {
    return this.http.put<Observable<any>>(`${environment.baseApiUrl}/empresa/${idempresa}`, null);
  }


}
