import { Component, Input, OnInit } from '@angular/core';
import { Alert } from '../interface/alert/alert';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent {
  @Input() alert: Alert = {
    status: 0,
    mensagem: ''
  }

  ngOnInit(): void {

  }
}
