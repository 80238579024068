import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { EmpresaService } from 'src/app/modules/cadastro/services/empresa/empresa.service';
import { UsuarioService } from '../../cadastro/services/usuario/usuario.service';
import { FuncaoService } from '../../cadastro/services/funcao/funcao.service';
import { StatusfuncionarioService } from '../../cadastro/services/statusfuncionario/statusfuncionario.service';
import { TagService } from '../../cadastro/services/tag/tag.service';

import { EventoService } from '../../cadastro/services/evento/evento.service';
import { FuncionarioService } from '../../cadastro/services/funcionario/funcionario.service';
import {
  MatPaginator,
  MatPaginatorModule,
  PageEvent,
} from '@angular/material/paginator';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.css'],
})
export class DatatableComponent implements OnInit {
  @Input() grid: Array<any> = [];
  @Input() thtable: Array<any> = [];
  @Input() rotaedicao: string = '';
  @Input() idedit: string = '';
  @Input() rota: string = '';

  @Output() paginacao = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  totalregistros: number = 0;
  aux_grid: Array<any> = [];  // só serve como garda dos objetos do grid
  indices: Array<string> = [];

  //pagina selecionada

  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];

  showPageSizeOptions = true;
  showFirstLastButtons = true;

  pageEvent: PageEvent = new PageEvent();

  constructor(
    private router: Router,
    private usuarioservice: UsuarioService,
    private empresaservice: EmpresaService,
    private funcaoservice: FuncaoService,
    private StatusfuncionarioService: StatusfuncionarioService,
    private tagservice: TagService,
    private funcionarioservice: FuncionarioService,
    private eventoservice: EventoService
  ) {}

  ngOnInit(): void {
    this.paginator._intl.itemsPerPageLabel = 'Itens por página';
    this.paginator._intl.firstPageLabel = 'Primeira página';
    this.paginator._intl.lastPageLabel = 'Última página';
    this.paginator._intl.nextPageLabel = 'Próxima página';
    this.paginator._intl.previousPageLabel = 'Página anterior';

    this.totalregistros = this.grid.length;
    this.aux_grid = this.grid;

    if (this.grid.length > 0) this.indices = this.thtable[1].indices;

    this.setPaginacao(0, this.pageSize);
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.totalregistros = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;

    let inicio = this.pageSize * this.pageIndex;
    let fim = this.pageSize * this.pageIndex + this.pageSize;

    this.setPaginacao(inicio, fim);
  }

  setPaginacao(inicio, fim) {
    var aux = this.aux_grid;
    this.grid = aux.filter((aux, indice) => {
      return indice >= inicio && indice < fim;
    });
  }

  pesquisa: string = '';

  pesquisaDataTable(pesquisa: string) {
    var aux = this.aux_grid;

    if (pesquisa.length == 0) {      
      this.totalregistros = this.aux_grid.length;

      this.grid = this.aux_grid.filter((aux, indice) => {
        return indice >= 0 && indice < this.pageSize;
      });
      
      this.totalregistros = this.aux_grid.length;
    } else {
      this.grid = aux.filter((aux) => {
        var achou: Boolean = false;
        this.indices.forEach((element) => {
          if (aux[element].toLowerCase().indexOf(pesquisa.toLowerCase()) != -1)
            achou = true;
        });
        return achou;
      });
      this.totalregistros = this.grid.length;
    }
    

    return this.grid;
  }

  abreEdicao(griditem: any) {
    this.router.navigate([this.rotaedicao], {
      queryParams: { id: griditem[this.idedit] },
      queryParamsHandling: 'merge',
    });
  }

  delete(item: any) {
    switch (this.rota) {
      case 'usuario':
        this.usuarioservice.deleteUsuario(item[this.idedit]).subscribe({
          next: (result) => {
            location.reload();
          },
          error: (e) => {
            this.redireciona('usuario');
          },
        });
        break;
      case 'empresa':
        this.empresaservice.deleteEmpresa(item[this.idedit]).subscribe({
          next: (result) => {
            location.reload();
          },
          error: (e) => {
            this.redireciona('empresa');
          },
        });
        break;
      case 'funcao':
        this.funcaoservice.deleteFuncao(item[this.idedit]).subscribe({
          next: (result) => {
            location.reload();
          },
          error: (e) => {
            this.redireciona('funcao');
          },
        });
        break;
      case 'status-funcionario':
        this.StatusfuncionarioService.deleteStatusFuncionario(
          item[this.idedit]
        ).subscribe({
          next: (result) => {
            location.reload();
          },
          error: (e) => {
            this.redireciona('status-funcionario');
          },
        });
        break;
      case 'tag':
        this.tagservice.deleteTag(item[this.idedit]).subscribe({
          next: (result) => {
            location.reload();
          },
          error: (e) => {
            this.redireciona('tag');
          },
        });
        break;
      case 'funcionario':
        this.funcionarioservice.deleteFuncionario(item[this.idedit]).subscribe({
          next: (result) => {
            location.reload();
          },
          error: (e) => {
            this.redireciona('funcionario');
          },
        });
        break;
      case 'evento':
        this.eventoservice.deleteEvento(item[this.idedit]).subscribe({
          next: (result) => {
            location.reload();
          },
          error: (e) => {
            this.redireciona('evento');
          },
        });
        break;
    }
  }

  reativar(item: any) {
    switch (this.rota) {
      case 'usuario':
        this.usuarioservice.reativarUsuario(item[this.idedit]).subscribe({
          next: (result) => {
            location.reload();
          },
          error: (e) => {
            this.redireciona('usuario');
          },
        });
        break;
      case 'empresa':
        this.empresaservice.reativarEmpresa(item[this.idedit]).subscribe({
          next: (result) => {
            location.reload();
          },
          error: (e) => {
            this.redireciona('empresa');
          },
        });
        break;
      case 'funcao':
        this.funcaoservice.reativarFuncao(item[this.idedit]).subscribe({
          next: (result) => {
            location.reload();
          },
          error: (e) => {
            this.redireciona('funcao');
          },
        });
        break;
      case 'status-funcionario':
        this.StatusfuncionarioService.reativarStatusFuncionario(
          item[this.idedit]
        ).subscribe({
          next: (result) => {
            location.reload();
          },
          error: (e) => {
            this.redireciona('status-funcionario');
          },
        });
        break;
      case 'tag':
        this.tagservice.reativarTag(item[this.idedit]).subscribe({
          next: (result) => {
            location.reload();
          },
          error: (e) => {
            this.redireciona('tag');
          },
        });
        break;
      case 'funcionario':
        this.funcionarioservice
          .reativarFuncionario(item[this.idedit])
          .subscribe({
            next: (result) => {
              location.reload();
            },
            error: (e) => {
              this.redireciona('funcionario');
            },
          });
        break;

      case 'evento':
        this.eventoservice.reativarEvento(item[this.idedit]).subscribe({
          next: (result) => {
            location.reload();
          },
          error: (e) => {
            this.redireciona('evento');
          },
        });
        break;
    }
  }

  redireciona(modulo: string) {
    this.router.navigate([`/crud/${modulo}`]);
  }
}
