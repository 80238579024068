import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventoInscricaoService {

  constructor(private http: HttpClient) { }

  getEventos(): Observable<any> {
    return this.http.get<Observable<any>>(`${environment.baseApiUrl}/inscricao/evento`);
  }

  getInformacoesEvento(idevento: number, idfuncionario: number): Observable<any> {
    return this.http.get<Observable<any>>(`${environment.baseApiUrl}/inscricao/evento/${idevento}/${idfuncionario}`);
  }

  setEventoFuncionario(params: any): Observable<any> {
    return this.http.post<Observable<any>>(`${environment.baseApiUrl}/inscricao/eventofuncionario`, params);
  }

  cancelaInscricao(idevento_funcionario: number): Observable<any> {
    return this.http.delete<Observable<any>>(`${environment.baseApiUrl}/inscricao/cancelarinscricao/${idevento_funcionario}`);
  }


}
