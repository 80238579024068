import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../generic/services/alert/alert.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(  private alertservice: AlertService) { }

  tagflow: any = ''
  ngOnInit(): void {
    this.tagflow = localStorage.getItem("tagflow")
    this.tagflow = JSON.parse(this.tagflow)

  }
}
