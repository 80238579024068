<app-cabecalho titulo="Pré Cadastro">
</app-cabecalho>
<div class="container-fluid container-form container-box mt-2 p-2">
    <form [formGroup]="formfuncionario" (submit)="onSubmit()" *ngIf="sucesso===0">
        <div class="row">
            <div class="form-group col-md-4">
                <label for="nome">Nome</label>
                <app-validator-class
                    [status]="formfuncionario.controls.nome.valid || !formfuncionario.controls.nome.touched">
                    <input type="text" class="form-control" id="nome" placeholder="Digite o Nome"
                        formControlName="nome">
                </app-validator-class>
            </div>
            <div class="form-group col-md-4">
                <label for="cpf">CPF</label>
                <app-validator-class
                    [status]="formfuncionario.controls.cpf.valid || !formfuncionario.controls.cpf.touched">
                    <input type="text" class="form-control" id="cpf" placeholder="Digite o cpf" formControlName="cpf" mask="000.000.000-00">
                </app-validator-class>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-4">
                <label for="data_nascimento">Data de Nascimento</label>
                <app-validator-class
                    [status]="formfuncionario.controls.data_nascimento.valid || !formfuncionario.controls.data_nascimento.touched">
                    <input type="date" class="form-control" id="data_nascimento" placeholder="Digite o data_nascimento"
                        formControlName="data_nascimento">
                </app-validator-class>
            </div>
            <div class="form-group col-md-4">
                <label for="celular">Celular</label>
                <app-validator-class
                    [status]="formfuncionario.controls.celular.valid || !formfuncionario.controls.celular.touched">
                    <input type="text" class="form-control" id="celular" placeholder="Digite o celular"  mask="(00)00000-0000"  formControlName="celular">
                </app-validator-class>
            </div>
            <div class="form-group col-md-4">
                <label for="email">Email</label>
                <app-validator-class
                    [status]="formfuncionario.controls.email.valid || !formfuncionario.controls.email.touched">
                    <input type="text" class="form-control" id="email" placeholder="Digite o email"
                        formControlName="email">
                </app-validator-class>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-4">
                <label for="foto">Carregar foto</label>
                <app-validator-class [status]="formfuncionario.controls.foto.valid">
                    <input type="file" class="form-control" id="foto" placeholder="insira a foto"
                        accept="image/png, image/jpeg" (change)="onFileSelected($event)">
                       
                    </app-validator-class>
                    
                    
                </div>
                <div class="form-group col-md-2">
                <p class="p-0 m-1">Capturar Foto</p>

                <button type="button" class="btn btn-lg btn-outline-primary" (click)="open(content)" [disabled]="dispositivos == 0"><i class="fa-solid fa-camera"></i></button>
                
                </div>

            <div class="form-group col-md-3 m-2">
                 <img class="imagem-redonda-w100" *ngIf="selectedImage" [src]="selectedImage" alt="Imagem Carregada">
             </div>

            
        </div>

        <div class="row">
            <div class="col-md  d-flex justify-content-end align-items-end">
                <button type="submit" class="btn_icon"><i class="fa-solid fa-floppy-disk"></i></button>
            </div>
        </div>
    </form>   
</div>

<ng-template #content let-modal NgIf="mediaDevices">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Capturar Foto</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click'); this.showWebcam = !this.showWebcam;"></button>
	</div>
	<div class="modal-body">
		<form>
			<div class="md-3">
				<div class="d-flex justify-content-center">
                    <webcam [height]="300" [width]="300" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
                        *ngIf="showWebcam" [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
                        [videoOptions]="videoOptions" [imageQuality]="1" (cameraSwitched)="cameraWasSwitched($event)"
                        (initError)="handleInitError($event)">
                    </webcam>
                
                    
                </div>
                <div class="d-flex justify-content-center">
            
                  
                    
                    <button class="btn btn-lg btn-outline-primary" (click)="triggerSnapshot();modal.dismiss('Cross click')"
                    *ngIf="showWebcam" aria-label="Close"><i class="fa-solid fa-camera"></i>
                </button>
                
                <button class="btn btn-lg btn-outline-success" (click)="showNextWebcam(true);"
            
                *ngIf="multipleWebcamsAvailable &&  showWebcam"><i class="fa-solid fa-repeat"></i>
            </button>
            
            </div>
				</div>
		</form>
	</div>
	
</ng-template>


