<app-cabecalho titulo="Recuperação de Senha" rotaanterior="/login">
</app-cabecalho>

<div class="container-fluid container-form container-box mt-2 p-2">
    <form [formGroup]="formesquecisenha" (submit)="onSubmit()">
        <div class="row   d-flex justify-content-center">
            <div class="form-group col-md-4">
                <label for="cpf">CPF</label>
                <app-validator-class
                    [status]="formesquecisenha.controls.cpf.valid || !formesquecisenha.controls.cpf.touched">
                    <input type="cpf" class="form-control" id="cpf" placeholder="Informe o CPF" formControlName="cpf"  mask="000.000.000-00" type="text">
                </app-validator-class>
            </div>
        </div>
        <div class="row d-flex justify-content-center">
            <div class="col-md-4   d-flex justify-content-end align-items-end">
                <button type="submit" class="btn_icon"><i class="fa-solid fa-floppy-disk"></i> Enviar</button>
            </div>
        </div>
    </form>
    <div class="alert alert-success" role="alert" *ngIf="sucesso===1">
        <p>Foi enviado um e-mail de confirmação para definição da senha de acesso para o email {{email}}.</p>
    </div>
</div>