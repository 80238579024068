import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { retry } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GenericService {

  constructor() { }



  validaSenha(formGroup: FormGroup) {
    const senhaControl = formGroup.get('senha');
    const confirmacaoSenhaControl = formGroup.get('confirmacaosenha');

    if (senhaControl?.value !== confirmacaoSenhaControl?.value) {
      confirmacaoSenhaControl?.setErrors({ passwordMismatch: true });
    } else {
      confirmacaoSenhaControl?.setErrors(null);
    }
  }

  validaComplexidade(formControl: AbstractControl) {
    if (!formControl.parent) {
      return null;
    }

    let senha = formControl.parent.get('senha')!.value

    const hasLetter = /[a-zA-Z]/.test(senha);
    const hasNumber = /\d/.test(senha);

    if (hasLetter && hasNumber)
      return null
    else
      return 'erro'
  }

  validarCPF(formControl: AbstractControl) {
    if (!formControl.parent) {
      return null;
    }

    let cpf = formControl.parent.get('cpf')!.value;
    // Remover caracteres não numéricos
    cpf = cpf.replace(/\D/g, '');    
    

    // Verificar se o CPF possui 11 dígitos
    if (cpf.length !== 11) {
      return 'erro';
    }

    // Verificar se todos os dígitos são iguais
    if (/^(\d)\1+$/.test(cpf)) {
      return 'erro';
    }

    // Validar o primeiro dígito verificador
    let soma = 0;
    for (let i = 0; i < 9; i++) {
      soma += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    if (resto !== parseInt(cpf.charAt(9))) {
      return 'erro';
    }

    // Validar o segundo dígito verificador
    soma = 0;
    for (let i = 0; i < 10; i++) {
      soma += parseInt(cpf.charAt(i)) * (11 - i);
    }
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    if (resto !== parseInt(cpf.charAt(10))) {
      return 'erro';
    }

    return null;
  }


  verificaSeRotaLogado(rota: string): boolean {
    switch (rota) {
      case 'primeiroacesso':
        return false
        break
      case 'redefinir-senha':
        return false
        break
      case 'esqueci-a-senha':
        return false
        break
      default:
        return true
        break
    }
  } 

  limpaCpf(cpf: string): string {
    if (!cpf) return '';
    return cpf.replace(/[.-]/g, '');
  }


  setEventoLocalStorage(idevento){
    localStorage.setItem('idevento', idevento)
  }
  getEventoLocalStorage():number {
    return parseInt(localStorage.getItem('idevento')) || null
  }

}
