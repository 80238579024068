import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InscricaoFuncaoComponent } from './inscricao-funcao/inscricao-funcao.component';
import { ConsultaInscricoesComponent } from './consulta-inscricoes/consulta-inscricoes.component';

const routes: Routes = [
  {
    path: '',
    component: ConsultaInscricoesComponent
  },
  {
    path: 'inscricaofuncao',
    component: InscricaoFuncaoComponent
  },
];



@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InscricaoRoutingModule {



}
