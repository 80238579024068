import { Component, TemplateRef, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericService } from 'src/app/shared/services/generic.service';
import { timeout, timer } from 'rxjs';
import { FuncionarioService } from '../services/funcionario/funcionario.service';
import { AlertService } from '../../generic/services/alert/alert.service';
import {Subject, Observable} from 'rxjs';
import {WebcamImage, WebcamInitError, WebcamUtil} from 'ngx-webcam';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-primeiro-acesso',
  templateUrl: './primeiro-acesso.component.html',
  styleUrls: ['./primeiro-acesso.component.css'],
})
export class PrimeiroAcessoComponent {
  sucesso: number = 0;
  funcionario: any = []
  formfuncionario: any = FormGroup
  closeResult: string;
  dispositivos: number;

  constructor(
    private route: ActivatedRoute,
    private formbuilder: FormBuilder,
    private router: Router,
    private funcionarioservice: FuncionarioService,
    private genericservice: GenericService,
    private alertservice: AlertService
  ) {
    this.formfuncionario = null
    
  }

  ngOnInit(): void {
    this.loadForm()

    WebcamUtil.getAvailableVideoInputs()
    .then((mediaDevices: MediaDeviceInfo[]) => {
      
      this.dispositivos = mediaDevices.length;
    });
    
  }

  loadForm() {
    this.formfuncionario = this.formbuilder.group({
      nome: ['', Validators.required],
      cpf: ['', [Validators.required, this.genericservice.validarCPF]],      
      data_nascimento: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      celular: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(11),
          Validators.required
        ],
      ],
      foto: ['', Validators.required],
    });
  }

  getfuncao() {
    return this.funcionario;
  }

  onSubmit() {
    if (this.formfuncionario.invalid) {
      this.alertservice.setValue({
        status: 0,
        mensagem: 'Preencha o formulário corretamente.',
      });
      return;
    }

    this.funcionarioservice
      .newFuncionario(this.formfuncionario.value)
      .subscribe({
        next: (result: any) => {
          this.alertservice.setValue({
            status: result.status,
            mensagem: result.dado
          })

          setTimeout(() => this.redirecionaConsulta(), 10000);
        },
        error: (e) => {          
          setTimeout(() => this.redirecionaConsulta(), 5000);
        },
      });
  }

  redirecionaConsulta() {
    this.router.navigate(['/login']);
  }

  selectedImage: string | null = null;

  onFileSelected(event: any) {
    const file = event.target.files[0];

    const maxSizeInBytes = 4 * 1024 * 1024; // 5 MB (exemplo de limite de 5 MB)

    if (file) {
      if (file.size > maxSizeInBytes)
        this.alertservice.setValue({
          status: 0,
          mensagem: 'A imagem deve ter no máximo 4 MB',
        });
      else {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.selectedImage = e.target.result;
          this.formfuncionario.controls.foto.setValue(this.selectedImage);
        };
        reader.readAsDataURL(file);
      }
    }
  }

  formatPhoneNumber() {
    var phoneNumber: string = '';
    
    // Remove caracteres não numéricos do número de telefone    
    let cleaned = this.formfuncionario.controls.celular.value.replace(/\D/g, '')

    // Aplica a máscara ao número de telefone
    if (cleaned.length > 0) {
      phoneNumber = cleaned.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
    } else {
      phoneNumber = cleaned
    }
    this.formfuncionario.controls.celular.setValue(phoneNumber)
    
  }

  /*webcam*/
  public showWebcam = false;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {
    width: 360,
    height: 270
  };

  
  public errors: WebcamInitError[] = [];

  // latest snapshot
  public webcamImage: WebcamImage = null;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();
  public triggerSnapshot(): void {
    this.trigger.next();
  }
  
  public toggleWebcam(): void {
    WebcamUtil.getAvailableVideoInputs()
    .then((mediaDevices: MediaDeviceInfo[]) => {

      this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
    });
    this.showWebcam = !this.showWebcam;
  }
  
  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }
  
  public showNextWebcam(directionOrDeviceId: boolean|string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }
  


  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.selectedImage = this.webcamImage.imageAsDataUrl;
    this.formfuncionario.controls.foto.setValue(this.webcamImage.imageAsDataUrl);
    this.showWebcam = !this.showWebcam;   
    
  }
  
  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }
  
  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }
  
  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }



/*modal*/
  
activeModal = inject(NgbActiveModal);

public modalService = inject(NgbModal);

open(content: TemplateRef<any>) {
  if(this.showWebcam == true){
    this.showWebcam = false;
  }
  
  this.toggleWebcam()
  this.modalService.dismissAll();
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
    (result) => {
      this.closeResult = `Closed with: ${result}`;
    },
    (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    },
  );
}

private getDismissReason(reason: any): string {
  switch (reason) {
    case ModalDismissReasons.ESC:
      return 'by pressing ESC';
    case ModalDismissReasons.BACKDROP_CLICK:
      return 'by clicking on a backdrop';
    default:
      return `with: ${reason}`;
  }
}
}

