import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TagService {


  constructor(private http: HttpClient) { }

  getTags(): Observable<any> {
    return this.http.get<Observable<any>>(`${environment.baseApiUrl}/tag`);
  }

  getTagById(idtag: number): Observable<any> {
    return this.http.get<Observable<any>>(`${environment.baseApiUrl}/tag/${idtag}`);
  }

  getTagByCodigo(codigo: string): Observable<any> {
    return this.http.get<Observable<any>>(`${environment.baseApiUrl}/tag/codigo/${codigo}`);
  }

  getTagEditById(idtag: number): Observable<any> {
    return this.http.get<Observable<any>>(`${environment.baseApiUrl}/tag/edit/${idtag}`);
  }

  updateTag(params: any): Observable<any> {
    return this.http.put<Observable<any>>(`${environment.baseApiUrl}/tag`, params);
  }

  newTag(params: any) {
    return this.http.post<Observable<any>>(`${environment.baseApiUrl}/tag`, params);
  }

  deleteTag(idtag: number): Observable<any> {
    return this.http.delete<Observable<any>>(`${environment.baseApiUrl}/tag/${idtag}`);
  }

  reativarTag(idtag: number): Observable<any> {
    return this.http.put<Observable<any>>(`${environment.baseApiUrl}/tag/${idtag}`, null);
  }


}