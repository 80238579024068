
<div class="container">
<div class="row">
<div class="col-8 col-sm-9 col-md-9 col-lg-10 col-xl-10">

    <nav class="navbar navbar-expand-lg menu">
      <a routerLink="/inscricao">
        <img src="../../../assets/img/tagflowti_logo.png" class="navbar-brand" alt="Logo" #imgheader />
      </a>      
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText"
          aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation" >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarText" >
          <ul class="navbar-nav mr-auto mt-2 mt-lg-0" id="menuprincipal" *ngIf="!isautenticado">
            <li class="nav-item active">
              <a routerLink="/login" class="nav-link itemmenu">
                <i class="fas fa-lock iconemenu"></i>
                <span class="ms-1 d-sm-inline"> Login </span>
              </a>
            </li>
            <li class="nav-item active">
              <a href="{{ site }}" class="nav-link itemmenu">
                <i class="fas fa-globe iconemenu"></i>
                <span class="ms-1 d-sm-inline"> Site </span>
              </a>
            </li>
          </ul>
          <ul class="navbar-nav mr-auto mt-2 mt-lg-0" id="menuprincipal" *ngIf="isautenticado">
            <li class="nav-item dropdown" *ngIf="tagflow?.funcionario?.idperfil == 1 || tagflow?.funcionario?.idperfil == 2 || tagflow?.funcionario?.idperfil == 3 ">
              <a href="#" class="nav-link itemmenu dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="fas fa-drum iconemenu"></i>
                <span class="ms-1 d-sm-inline"> Meus Dados </span>
              </a>
              <div class="dropdown-menu">
                <a routerLink="/usuario/perfil/{{tagflow?.funcionario?.idfuncionario}}" class="subitemmenu dropdown-item">
                  <i class="fas fa-user iconemenu"></i>
                  <span class="d-sm-inline"> Perfil </span>
                </a>                            
                <a routerLink="/inscricao" class="subitemmenu dropdown-item">
                  <i class="fas fa-thumbs-up iconemenu"></i>
                  <span class="d-sm-inline"> Inscrições </span>
                </a>              
              </div>
            </li>
            <li class="nav-item dropdown" *ngIf="tagflow?.funcionario?.idperfil == 3">
              <a href="#" class="nav-link itemmenu dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="fas fa-drum iconemenu"></i>
                <span class="ms-1 d-sm-inline"> Gestão de Eventos </span>
              </a>
              <div class="dropdown-menu">
                <a routerLink="/gestao-evento/validacao-inscricoes" class="subitemmenu dropdown-item">
                  <i class="fas fa-thumbs-up iconemenu"></i>
                  <span class="d-sm-inline"> Validação de Inscrições </span>
                </a>
                <a routerLink="/gestao-evento/vincular-tag" class="subitemmenu dropdown-item">
                  <i class="fas fa-user-check iconemenu"></i>
                  <span class="d-sm-inline"> Vincular TAG </span>
                </a>
                <a routerLink="/gestao-evento/iniciar-servico" class="subitemmenu dropdown-item">
                  <i class="fas fa-sign-in-alt iconemenu"></i>
                  <span class="d-sm-inline"> Iniciar Serviço </span>
                </a>
                <a routerLink="/gestao-evento/finalizar-servico" class="subitemmenu dropdown-item">
                  <i class="fas fa-sign-out-alt iconemenu"></i>
                  <span class="d-sm-inline"> Finalizar Serviço </span>
                </a>
                <a routerLink="/gestao-evento/dashboard" class="subitemmenu dropdown-item">
                  <i class="fa-solid fa-people-roof iconemenu"></i>
                  <span class="d-sm-inline"> Dashboards </span>
                </a>
              </div>
            </li>
            <li class="nav-item dropdown" *ngIf="tagflow?.funcionario?.idperfil == 3">
              <a href="#" class="nav-link itemmenu dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="fas fa-gears iconemenu"></i>
                <span class="ms-1 d-sm-inline"> Cadastros </span>
              </a>
              <div class="dropdown-menu">
                <a routerLink="/crud/evento" class="subitemmenu dropdown-item">
                  <i class="fas fa-drum iconemenu"></i>
                  <span class="d-sm-inline"> Eventos </span>
                </a>
                <a routerLink="/crud/funcionario" class="subitemmenu dropdown-item">
                  <i class="fa-solid fa-people-roof iconemenu"></i>
                  <span class="d-sm-inline"> Funcionarios </span>
                </a>
                <a routerLink="/crud/empresa" class="subitemmenu dropdown-item">
                  <i class="fa-solid fa-building iconemenu"></i>
                  <span class="d-sm-inline"> Empresas </span>
                </a>
                <a routerLink="/crud/funcao" class="subitemmenu dropdown-item">
                  <i class="fa-regular fa-file-lines iconemenu"></i>
                  <span class="d-sm-inline"> Funções </span>
                </a>
                <a routerLink="/crud/status-funcionario" class="subitemmenu dropdown-item">
                  <i class="fa-solid fa-address-card iconemenu"></i>
                  <span class="d-sm-inline"> Status Funcionário </span>
                </a>
<!--                 <a routerLink="/crud/tag" class="subitemmenu dropdown-item">
                  <i class="fa-solid fa-tags iconemenu"></i>
                  <span class="d-sm-inline"> TAG </span>
                </a> -->
              </div>
            </li>
          </ul>
        </div>
    </nav>
  
</div>

<div class="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 d-flex justify-content-center aling-itens-center">
<div  *ngIf="tagflow">
  <i *ngIf="!tagflow.funcionario.foto" class="fas fa-user-circle fs-1 m-3"></i>    
  <img *ngIf="tagflow.funcionario.foto" src="{{ tagflow.funcionario.foto }}" class="imagem-redonda-menu m-2" alt="Foto" />
  <label class="nome-user-logado">Sr.(a) {{ tagflow.funcionario.nome }}</label>
  <a (click)="sair()" href="{{ urlsite }}" class="nav-link itemmenu">
    <i class="fas fa-sign-out-alt"></i>
    <span class="ms-1 d-sm-inline"> Sair </span>
  </a>
</div>
</div>
</div>
</div>