import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericService } from 'src/app/shared/services/generic.service';
import { EsqueciSenhaService } from '../services/esqueci-senha/esqueci-senha.service';


@Component({
  selector: 'app-esqueci-senha',
  templateUrl: './esqueci-senha.component.html',
  styleUrls: ['./esqueci-senha.component.css']
})
export class EsqueciSenhaComponent {


  formesquecisenha: any = FormGroup
  token: string = ''

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formbuilder: FormBuilder,
    private genericservice: GenericService,
    private esqueciasenha: EsqueciSenhaService
  ) {
  }

  ngOnInit(): void {

    this.loadForm()
  }



  loadForm() {
    this.formesquecisenha = this.formbuilder.group({
      cpf: ['', [Validators.required, Validators.minLength(11), this.genericservice.validarCPF]]
    })
  }


  sucesso: any = 0
  email: string = ''
  onSubmit() {

    this.esqueciasenha.esqueciASenha(this.formesquecisenha.value).subscribe({
      next: (result) => {
        this.sucesso = 1
        this.email = result.dado
        setTimeout(() => this.redirecionaConsulta(), 5000)
      },
      error: (e) => {
        this.sucesso = 2
        setTimeout(() => this.redirecionaConsulta(), 5000)
      }
    })

  }

  redirecionaConsulta() {
    this.router.navigate(
      ["/login"]
    );
  }

}
