import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { AuthGuard } from './core/guards/auth.guard';
import { GenericModule } from './modules/generic/generic.module';
import { HttpReqInterceptor } from './core/interceptor/request/http-req.interceptor';
import { HttpResInterceptor } from './core/interceptor/response/http-res.interceptor';
import { MenuComponent } from './modules/inicio/menu/menu.component';
import { HomeComponent } from './modules/inicio/home/home.component';
import { LoginComponent } from './modules/inicio/login/login.component';
import { PrimeiroAcessoComponent } from './modules/inicio/primeiro-acesso/primeiro-acesso.component';
import { RedefinirSenhaComponent } from './modules/inicio/redefinir-senha/redefinir-senha.component';
import { EsqueciSenhaComponent } from './modules/inicio/esqueci-senha/esqueci-senha.component';
import { InscricaoModule } from './modules/inscricao/inscricao.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WebcamModule } from 'ngx-webcam';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { NGX_MASK_CONFIG, NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';




@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HomeComponent,
    LoginComponent,
    PrimeiroAcessoComponent,
    RedefinirSenhaComponent,
    EsqueciSenhaComponent,    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    CommonModule,
    GenericModule,
    InscricaoModule,
    BrowserAnimationsModule,
    WebcamModule,
    NgxMaskDirective, 
    NgxMaskPipe,
    
  ],
  exports: [

  ],
  providers: [
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpReqInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpResInterceptor,
      multi: true
    },
    NgbActiveModal,
    provideNgxMask()
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {

}
