import { Component, ElementRef, EventEmitter, Output, ViewChild, inject, AfterViewInit, TemplateRef, Input } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxScannerQrcodeComponent, NgxScannerQrcodeService, ScannerQRCodeConfig, ScannerQRCodeResult } from 'ngx-scanner-qrcode';
import { AlertService } from '../services/alert/alert.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.css']
})
export class ScannerComponent {
  @ViewChild('action') action!: NgxScannerQrcodeComponent;
  @Output() code = new EventEmitter<string>;
  @Input() estado: boolean = false;
  @Input() modo: number = 1; // 1 - continuo,  2 - com pausa após a leitura 
  
  closeResult: string;
  public config: ScannerQRCodeConfig = {
    constraints: {
      video: {
        width: 50
      },
    },
    // canvasStyles: [
    //   { /* layer */
    //     lineWidth: 1,
    //     fillStyle: '#00950685',
    //     strokeStyle: '#00950685',
    //   },
    //   { /* text */
    //     font: '17px serif',
    //     fillStyle: '#ff0000',
    //     strokeStyle: '#ff0000',
    //   }
    // ],
  };
  constructor(
    private alertservice: AlertService,
    private qrcode: NgxScannerQrcodeService

  ) {


  }

  ngAfterViewInit(): void {
    this.action.start();
    console.log('roudou o inicio ');
    
    
  }
  scannerOptions: any = {}; 
  ngOnInit() {
   
  }




  public onEvent(e, action?: any) {

    if (this.modo == 2) {

      action.stop()
    }


    return this.code.emit(e[0].value)


  }
  public handle(action: any, fn: string): void {
    const playDeviceFacingBack = (devices: any[]) => {
      // front camera or back camera check here!
      const device = devices.find(f => (/back|rear|environment/gi.test(f.label))); // Default Back Facing Camera
      action.playDevice(device ? device.deviceId : devices[0].deviceId);
    }

    if (fn === 'start') {
      action[fn](playDeviceFacingBack).subscribe((r: any) => console.log(fn, r), alert);
    } else {
      action[fn]().subscribe((r: any) => console.log(fn, r), alert);
    }
  }

}
