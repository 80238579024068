<div class="container-fluid container-box mt-2 p-2">
  <div class="row justify-content-end">
    <div class="col-md-4">
      <div class="form-floating">
        <input
          type="text"
          class="form-control"
          [(ngModel)]="pesquisa"
          (keyup)="pesquisaDataTable(pesquisa)"
          name="pesquisa"
          id="pesquisa"
          placeholder="Pesquisar"
        />
        <label for="pesquisa">Pesquisar</label>
      </div>
    </div>
  </div>
  <div class="row p-3">
    <div class="col table-responsive m-0 p-0" *ngIf="grid.length > 0">
      <table class="table tabela">
        <thead>
          <tr>
            <th *ngFor="let thitem of this.thtable[0].titulo" scope="col">
              {{ thitem }}
            </th>
            <th>AÇÕES</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let griditem of grid">
            <td class="texto" *ngFor="let thitem of this.thtable[1].indices">
              {{ griditem[thitem] }}
            </td>
            <td>
              <a (click)="abreEdicao(griditem)" class="m-2" title="Editar">
                <i class="fa-regular fa-pen-to-square botaoacao"></i>
              </a>
              <a
                (click)="delete(griditem)"
                class="m-2"
                *ngIf="griditem.status == 'Ativo'"
                title="Desativar"
              >
                <i class="fa-solid fa-trash-can botaoacao"></i>
              </a>
              <a
                (click)="reativar(griditem)"
                class="m-2"
                *ngIf="griditem.status == 'Excluido'"
                title="Reativar"
              >
                <i class="fa-regular fa-square-check botaoacao"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-paginator
        class="custom-paginator"
        #paginator
        (page)="handlePageEvent($event)"
        [length]="totalregistros"
        [pageSize]="pageSize"
        [showFirstLastButtons]="showFirstLastButtons"
        [pageIndex]="pageIndex"
        [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"        
      >
      </mat-paginator>
    </div>
  </div>

</div>
