
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FuncionarioService {

  constructor(private http: HttpClient) { }

  getFuncionario(): Observable<any> {
    return this.http.get<Observable<any>>(`${environment.baseApiUrl}/funcionario`);
  }

  getFuncionarioById(idfuncionario: number): Observable<any> {
    return this.http.get<Observable<any>>(`${environment.baseApiUrl}/funcionario/${idfuncionario}`);
  }

  getFuncionarioEditById(idfuncionario: number): Observable<any> {
    return this.http.get<Observable<any>>(`${environment.baseApiUrl}/funcionario/edit/${idfuncionario}`);
  }

  getFuncionarioByCpf(cpf: string): Observable<any> {
    return this.http.get<Observable<any>>(`${environment.baseApiUrl}/funcionario/cpf/${cpf}`);
  }

  updateFuncionario(params: any): Observable<any> {
    return this.http.put<Observable<any>>(`${environment.baseApiUrl}/funcionario`, params);
  }

  newFuncionario(params: any) {
    return this.http.post<Observable<any>>(`${environment.baseApiUrl}/funcionario`, params);
  }

  deleteFuncionario(idfuncionario: number): Observable<any> {
    return this.http.delete<Observable<any>>(`${environment.baseApiUrl}/funcionario/${idfuncionario}`);
  }

  reativarFuncionario(idfuncionario: number): Observable<any> {
    return this.http.put<Observable<any>>(`${environment.baseApiUrl}/funcionario/${idfuncionario}`, null);
  }



}
