import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  constructor() {
  }

  public isLoading: Subject<boolean> = new Subject<boolean>();

  show() {
    this.isLoading.next(true);
  }

  hide() {
    this.isLoading.next(false);
  }

  getIsloading(): Observable<boolean> {
    return this.isLoading.asObservable();
  }


}
