import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventoService {


  constructor(private http: HttpClient) { }

  getEventos(): Observable<any> {
    return this.http.get<Observable<any>>(`${environment.baseApiUrl}/evento`);
  }

  getEventoById(idevento: number): Observable<any> {
    return this.http.get<Observable<any>>(`${environment.baseApiUrl}/evento/${idevento}`);
  }

  getEventoEditById(idevento: number): Observable<any> {
    return this.http.get<Observable<any>>(`${environment.baseApiUrl}/evento/edit/${idevento}`);
  }

  updateEvento(params: any): Observable<any> {
    return this.http.put<Observable<any>>(`${environment.baseApiUrl}/evento`, params);
  }

  newEvento(params: any): Observable<any> {
    return this.http.post<Observable<any>>(`${environment.baseApiUrl}/evento`, params);
  }

  deleteEvento(idevento: number): Observable<any> {
    return this.http.delete<Observable<any>>(`${environment.baseApiUrl}/evento/${idevento}`);
  }

  reativarEvento(idevento: number): Observable<any> {
    return this.http.put<Observable<any>>(`${environment.baseApiUrl}/evento/${idevento}`, null);
  }

}
