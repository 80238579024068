import { Component } from '@angular/core';
import { EventoService } from '../services/evento/evento.service';

@Component({
  selector: 'app-consulta-inscricoes',
  templateUrl: './consulta-inscricoes.component.html',
  styleUrls: ['./consulta-inscricoes.component.css']
})
export class ConsultaInscricoesComponent {
  constructor(private eventoservice: EventoService) { }


  
  tagflow: any = ''
  ngOnInit(): void {
    this.tagflow = localStorage.getItem("tagflow")
    this.tagflow = JSON.parse(this.tagflow)
    this.getInscricoesEfentosByIdFuncionario(this.tagflow.funcionario.idfuncionario)
  }


  inscricoeseventos: Array<any> = []
  getInscricoesEfentosByIdFuncionario(idfuncionario: number) {
    this.eventoservice.getInscricoesEfentosByIdFuncionario(idfuncionario).subscribe({
      next: (result) => {
        this.inscricoeseventos = result.dado
      },
      error: (e) => {

      }
    })
  }


}
