import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-validator-class',
  templateUrl: './validator-class.component.html',
  styleUrls: ['./validator-class.component.css']
})
export class ValidatorClassComponent implements OnInit {

  @Input() status: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}
