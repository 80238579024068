import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { LoginService } from '../services/login/login.service';
import { TagflowService } from '../../generic/services/tagflow/tagflow.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  formlogin: any = FormControl;
  constructor(
    private formbuilder: FormBuilder,
    private router: Router,
    private loginservice: LoginService,
    private authguard: AuthGuard, 
    public tagflow: TagflowService
  ) {
    this.formlogin = null;
  }

  ngOnInit(): void {
    this.loadForm()
  }


  loadForm() {
    this.formlogin = this.formbuilder.group({
      email: ['', [Validators.required, Validators.email]],
      senha: ['', Validators.required],
    })
  }


  onSubmit() {
    this.loginservice.auth(this.formlogin.value).subscribe({
      next: (result) => {
        this.authguard.setAutenticated(true)
       // TEM QUE MELHORAR ESTA PARTE
        //  this.tagflow.setTagFlow(result)
         try {                            
          localStorage.setItem("tagflow", JSON.stringify(result))  
        } catch (error) {
          result['funcionario']['foto'] = ''
          localStorage.setItem("tagflow", JSON.stringify(result))
        } 
        
        this.router.navigate(
          ["/inscricao"]
        )
      },
      error: (e) => {
        this.authguard.setAutenticated(false)
      }
    })

  }
}
