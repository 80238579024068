import { Component, OnInit } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventoService } from '../../cadastro/services/evento/evento.service';
import { EventoInscricaoService } from '../services/evento-inscricao/eventoinscricao.service';
import { AlertService } from '../../generic/services/alert/alert.service';

@Component({
  selector: 'app-inscricao-funcao',
  templateUrl: './inscricao-funcao.component.html',
  styleUrls: ['./inscricao-funcao.component.css']
})
export class InscricaoFuncaoComponent {


  formfiltraevento: any = FormGroup
  forminscricaoevento: any = FormGroup
  formcancelarinscricao: any = FormGroup

  evento: any
  eventofuncoes: any
  eventos: any

  tagflow: any = ''
  inscricao: any = null

  constructor(
    private formbuilder: FormBuilder,
    private eventoinscricaoservice: EventoInscricaoService,
    private alertservice: AlertService
  ) {
  }

  ngOnInit(): void {

    this.tagflow = localStorage.getItem("tagflow")
    this.tagflow = JSON.parse(this.tagflow)

    this.loadForm()
    this.getEventos()



  }


  loadForm() {
    this.formfiltraevento = this.formbuilder.group({
      idevento: ['', Validators.required],
    })

    this.forminscricaoevento = this.formbuilder.group({
      idevento: ['', Validators.required],
      idfuncionario: [this.tagflow.funcionario.idfuncionario, Validators.required],
      idfuncao: ['', Validators.required],
      idempresa: ['', Validators.required],
    })

    this.formcancelarinscricao = this.formbuilder.group({
      idevento_funcionario: ['', Validators.required],
    })
  }



  getEventos() {
    this.eventoinscricaoservice.getEventos().subscribe({
      next: (result) => {
        this.eventos = result.dado
      },
      error: (e) => {

      }
    })
  }

  onSubmitFormFiltraEvento() {
    this.evento = []
    this.eventofuncoes = []
    this.inscricao = null
    this.formcancelarinscricao.reset
    if (this.formfiltraevento.valid)
      this.eventoinscricaoservice.getInformacoesEvento(this.formfiltraevento.controls.idevento.value, this.tagflow.funcionario.idfuncionario).subscribe({
        next: (result) => {
          this.evento = result.dado.evento
          this.eventofuncoes = result.dado.eventofuncoes
          this.forminscricaoevento.controls.idevento.setValue(this.evento.idevento)
          if (result.dado.inscricao) {
            this.inscricao = result.dado.inscricao
            this.formcancelarinscricao.controls.idevento_funcionario.setValue(this.inscricao.idevento_funcionario)
          }
        },
        error: (e) => {

        }
      })
  }


  onSubmitFormInscricaoEvento(eventofuncao: any) {
    this.forminscricaoevento.controls.idfuncao.setValue(eventofuncao.idfuncao)
    this.forminscricaoevento.controls.idempresa.setValue(eventofuncao.idempresa)
    if (this.forminscricaoevento.valid)
      this.eventoinscricaoservice.setEventoFuncionario(this.forminscricaoevento.value).subscribe({
        next: (result: any) => {
          this.inscricao = result.dado.inscricao
          this.alertservice.setValue({
            status: result.status,
            mensagem: result.dado.mensagem
          })
          this.formcancelarinscricao.controls.idevento_funcionario.setValue(this.inscricao.idevento_funcionario)
        },
        error: (e) => {

        }
      })
  }



  onSubmitFormCancelarInscricao() {
    if (this.formcancelarinscricao.valid)
      this.eventoinscricaoservice.cancelaInscricao(this.formcancelarinscricao.controls.idevento_funcionario.value).subscribe({
        next: (result: any) => {
          this.alertservice.setValue({
            status: result.status,
            mensagem: result.dado
          })
          this.inscricao = null
          this.onSubmitFormFiltraEvento()
        },
        error: (e) => {

        }
      })
  }


}
