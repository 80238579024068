import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private router: Router) { }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

    this.setAutenticated(this.verificaLocalStorage())



    if (!this.isAuthenticated)
      this.router.navigate(
        ["/login"]
      )
    return this.isAuthenticated;
  }

  private isAuthenticated: boolean = false;

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    this.setAutenticated(this.verificaLocalStorage())
    if (!this.isAuthenticated)
      this.router.navigate(
        ["/login"]
      )
    return this.isAuthenticated;
  }

  setAutenticated(auth: boolean) {
    this.isAuthenticated = auth
  }

  getAuthenticated() {
    return this.isAuthenticated
  }

  verificaLocalStorage() {
    var sessao = localStorage.getItem("tagflow")
    if (sessao) {
      this.setAutenticated(true);
      return true;
    } else {
      this.setAutenticated(false)
      return false;
    }
  }

}
