<div class="container-fluid row cabecalho container-box text-center">
  <div class="col-md-8 titulo m-0 p-0 col-sm-10 col-lg-9 	col-xl-11 col-10">
    {{titulo}}
  </div>
  <div class="col-md-4  m-0 p-2 col-sm-2 col-lg-3 col-xl-1 col-2  d-flex justify-content-end">
    <a *ngIf="rotaanterior" type="button" routerLink={{rotaanterior}} class="btn_icon">
      <i class="fa-solid fa-reply"></i>
    </a>
    <ng-content>
    </ng-content>
  </div>
</div>