import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { SpinnerService } from '../../../modules/generic/services/spinner/spinner.service';


@Injectable()
export class HttpReqInterceptor implements HttpInterceptor {

  constructor(public spinnerservice: SpinnerService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const rota: Array<any> = request.url.split('/')


    if (rota[3] === 'Auth')
      return next.handle(request)



    let token = ''
    if (localStorage.getItem("tagflow")) {
      var sessao: any = localStorage.getItem("tagflow")
      sessao = JSON.parse(sessao)
      token = sessao.Authorization
    }

    this.spinnerservice.show();
    var newrequest = request.clone({
      setHeaders: {
        Authorization: `bearer ${token}`
      }
    });

    return next.handle(newrequest)

  }
}
