import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(private http: HttpClient) { }

  getUsuarios(): Observable<any> {
    return this.http.get<Observable<any>>(`${environment.baseApiUrl}/usuario`);
  }

  getUsuarioById(idusuario: number): Observable<any> {
    return this.http.get<Observable<any>>(`${environment.baseApiUrl}/usuario/${idusuario}`);
  }

  getUsuarioEditById(idusuario: number): Observable<any> {
    return this.http.get<Observable<any>>(`${environment.baseApiUrl}/usuario/edit/${idusuario}`);
  }

  updateUsuario(params: any): Observable<any> {
    return this.http.put<Observable<any>>(`${environment.baseApiUrl}/usuario`, params);
  }

  newUsuario(params: any) {
    return this.http.post<Observable<any>>(`${environment.baseApiUrl}/usuario`, params);
  }

  deleteUsuario(idusuario: number): Observable<any> {
    return this.http.delete<Observable<any>>(`${environment.baseApiUrl}/usuario/${idusuario}`);
  }

  reativarUsuario(idusuario: number): Observable<any> {
    return this.http.put<Observable<any>>(`${environment.baseApiUrl}/usuario/${idusuario}`, null);
  }


}
